<template>
  <base-section id="Professional Details" color="white">
    <v-row>
      <v-col cols="12" sm="6">
        <v-card style="background: white" elevation="0">
          <div class="text-h4 font-weight-black ml-1">
            Pourquoi vous choisir?
          </div>
          <v-card-text>
            <v-icon class="ml-5" x-large color="rgba(157, 0, 255, 1)">
              mdi-format-quote-open
            </v-icon>
          </v-card-text>
          <div class="text-h5 font-weight-medium font-italic ml-5">
            Je suis le meilleur dans ce que je fais et je donne toujours le
            meilleur de moi-même. Je suis le meilleur dans ce que je fais et je
            donne toujours le meilleur de moi-même.
          </div>
          <v-card-text>
            <v-icon class="ml-10" x-large color="rgba(157, 0, 255, 1)">
              mdi-format-quote-close
            </v-icon>
          </v-card-text>
          <v-card-title class="text-h5 font-weight-bold"
            >M. le Propriétaire du site</v-card-title
          >
          <v-card-subtitle class="text-h6 font-weight-bold"
            >Artisan Plombier</v-card-subtitle
          >
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-img src="https://cdn.vuetifyjs.com/images/cards/cooking.png" />
      </v-col>
    </v-row>
  </base-section>
</template>

<script>
export default {
  name: "SectionWaveProfessionalDetails",

  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
